@import url(https://fonts.googleapis.com/css?family=Roboto:500,400,300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}
@keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}
@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
    font-size: 18px;
}
.material-icons.md-24 {
    font-size: 24px;
}
.material-icons.md-36 {
    font-size: 36px;
}
.material-icons.md-48 {
    font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

* {
    box-sizing: border-box;
    transition: 0.3s ease-in-out;
}

html,
body,
#root {
    padding: 0;
    margin: 0;
    height: 100%;
    background: #90a4ae url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABGCAYAAABxLuKEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wUeFiwOkbgy7QAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAMUlEQVR42u3QAQ0AAAgDoAe0f51bQzeIQAIAAAAAAAAAAAB/tB0LAAAAAAAAAAAA1yy2hwNKL8icmwAAAABJRU5ErkJggg==) center top repeat;
    background: radial-gradient(
        circle,
        rgba(176, 190, 197, 1) 0%,
        rgba(144, 164, 174, 1) 100%
    );
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

h1 {
    font-size: 96px;
    font-weight: 300;
    letter-spacing: -1.5px;
}

h2 {
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -0.5px;
}

h3 {
    font-size: 48px;
    font-weight: 400;
    letter-spacing: 0px;
}

h4 {
    font-size: 34px;
    font-weight: 400;
    letter-spacing: 0.25px;
}

h5 {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
}

h6 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
}

p {
    font-size: 16px;
    font-weight: 400;
}

b {
    font-weight: 500;
}

i {
    vertical-align: middle;
}

a {
    text-decoration: none;
}

.tag {
    font-style: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    color: #c17900;
    padding: 5px 7px;
    margin: 5px 10px;
    background: rgba(0, 0, 0, 0.1);
    background: rgba(251, 192, 45, 0.2);
    border-radius: 2px;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

button,
input[type="submit"],
.buttonLink {
    width: auto;
    padding: 10px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    background: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #2196f3;
}

button:hover,
input[type="submit"]:hover,
.buttonLink:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

button:focus,
input[type="submit"]:focus {
    outline: none;
}

button:disabled,
input[type="submit"]:disabled {
    cursor: default;
    background: none;
    color: rgba(0, 0, 0, 0.26);
}

.fab button {
    padding: 10px;
    background-color: #2196f3;
    color: #fff;
    border-radius: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.fab button:hover {
    background-color: #1976d2;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
}

.remove {
    color: #f44336;
}

input[type="number"] {
    text-align: right;
}

select:hover {
    cursor: pointer;
}

input[type="search"],
input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="password"],
textarea,
select {
    width: 100%;
    padding: 15px;
    margin: 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #f5f5f5;
    border-radius: 4px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    margin: 10px 0;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
    background: #eee;
    outline: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid #2196f3;
}

input:invalid + span:after {
    content: "✖";
    padding-left: 5px;
}

.App {
    position: fixed;
    right: 0;
    z-index: 1;
    width: calc(100vw - 80px);
    height: 100%;
}

.mainCard {
    max-height: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.empty {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    padding: 70px;
    padding-bottom: 50px;
    color: rgba(0, 0, 0, 0.54);
    text-align: center;
    font-size: 18px;
}

.empty i {
    margin: 20px;
}

.tipo {
    grid-area: tipo;
    position: relative;
    display: -webkit-flex;
    display: flex;
}

.tipo form {
    background: #fff;
    width: 100%;
}

.tipo > div {
    width: 100%;
}

.tipo button {
    border-radius: 100%;
}

.tipo button[type="submit"] i {
    color: #2196f3;
}

.tipo #newFabricante input,
.tipo #newModelo input {
    text-transform: capitalize;
}

.tipo #newFabricante > div,
.tipo #newModelo div,
.tipo #fabricante div,
.tipo #modelo div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.tipo #newFabricante > div > div {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.tipo #newFabricante.new ~ #fabricante,
.tipo #newFabricante.new ~ #modelo,
.tipo #newModelo.new ~ #modelo {
    opacity: 0;
    visibility: hidden;
}

.tipo #newFabricante.new,
.tipo #newModelo.new {
    opacity: 1;
    visibility: visible;
}

@media screen and (min-width: 1340px) {
    .headBar {
        height: 102px;
    }

    #searchCard {
        right: 35px;
        height: auto;
        max-height: calc(100vh - 70px);
        border-radius: 5px;
    }
}

#Login {
    position: fixed;
    z-index: 2;
    height: 100%;
    max-height: 100vh;
    width: 100vw;
    display: grid;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 50px; /* 
    background: linear-gradient(
        324deg,
        rgba(141, 45, 251, 0.1) 0%,
        rgba(33, 196, 243, 0.1) 100%
    ); */
    background: radial-gradient(
        circle,
        rgba(176, 190, 197, 1) 0%,
        rgba(144, 164, 174, 1) 100%
    );
    text-align: center;
}

#Login .dialog {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    background: none;
    border-radius: 10px;
    box-shadow: -12px -12px 12px 0 rgba(255, 255, 255, 0.1),
        12px 12px 12px 0 rgba(0, 0, 0, 0.1);
}

#Login .dialog input[type="text"],
#Login .dialog input[type="password"] {
    border-radius: 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: inset -6px -6px 12px 0 rgba(255, 255, 255, 0.1),
        inset 6px 6px 12px 0 rgba(0, 0, 0, 0.1);
}

#Login .dialog input[type="submit"] {
    color: rgba(255, 255, 255, 1);
    background: #2196f3;
    border: 1px solid #2196f3;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

#Login .dialog input[type="submit"]:hover {
    background-color: #1976d2;
    border: 1px solid #1976d2;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
}

.loading {
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
}

.loading h3 {
    display: inline-block;
    padding: 30px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.54);
}

.loading {
    height: 100%;
    width: 100%;
    padding: 50px;
    display: grid;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
}

.spinner {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

.snackbar {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 4;
    right: 0;
    bottom: 0;
    margin: 30px;
    min-width: 200px;
    min-height: 45px;
    background: #424242;
    background: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    font-size: 15px;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}

.snackbar--show {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
}

.fade-enter,
.fade-exit {
    transition: 0.5s ease opacity;
}
.fade-enter,
.fade-exit-active {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    z-index: 2;
}

.header {
    position: fixed;
    z-index: 2;
    opacity: 1;
    height: 100%;
    max-width: 80px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.54);
}

.header:hover {
    max-width: 250px;
}

.header > div:first-child {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 70px;
    height: 100%;
    min-width: 250px;
}

.header__logo {
    display: block;
    height: 102px;
    width: 100%;
    padding-top: 10px;
}

.header__logo img {
    display: inline-block;
    width: 80px;
    padding: 15px;
    vertical-align: middle;
}

.header .links {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.header__link {
    display: block;
    padding: 20px 0px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    border-left: 0px solid #455a64;
}

.header__link .md-dark {
    color: #263238;
    margin: 0 27px;
}

.header__link--active .md-dark {
    color: #fdd835;
}

.header__link:hover {
    border-left: 5px solid #455a64;
}

.header__link span {
    font-weight: 400;
    opacity: 0;
    padding: 7px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin-left: 0px;
}

.header:hover .header__link span {
    opacity: 1;
    margin-left: 10px;
}

.header__logout {
    width: 100%;
    padding: 10px 15px;
    margin: 0;
    border-left: 5px solid rgba(0, 0, 0, 0);
    text-align: left;
    font-size: 14px;
}

.header__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 14px;
    max-width: 80px;
    text-align: center;
    padding-bottom: 35px;
    color: rgba(0, 0, 0, 0.3);
}

.headBar {
    position: fixed;
    right: 0;
    z-index: 0;
    width: calc(100vw - 80px);
    background: #b0bec5;
    height: 102px;
    border-radius: 0 0 0 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

#SearchView {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    max-height: 100vh;
}

.searchCard {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "buscar vehiculoInfo"
        "busqueda reparaciones";
    justify-items: stretch;
    -webkit-align-items: stretch;
            align-items: stretch;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 600px;
    overflow: hidden;
    background: #e0e0e0;
    transition: 0.8s ease-in-out;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px 8px 8px 8px;
}

.searchCard--active {
    max-width: 1260px;
    border-radius: 8px 0 0 8px;
}

.buscar {
    grid-area: buscar;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px 20px 12px 30px;
    background: #fff;
    box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0 5px 0 0;
}

.buscar input {
    width: 100%;
    max-width: 400px;
    padding: 13px;
    margin: 0 20px;
    background: #f5f5f5;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    text-transform: uppercase;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.buscar input:hover,
.buscar input:focus {
    background: #eee;
}

.buscar .fab {
    position: absolute;
    right: 0;
    bottom: -50%;
    z-index: 500;
    padding: 0 25px;
}

.busqueda {
    grid-area: busqueda;
    position: relative;
    min-width: 600px;
    max-width: 600px;
    padding-bottom: 70px;
    overflow-y: auto;
    background: #fff;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 5px 0;
}

.busqueda__head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #f5f5f5;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0;
    padding: 10px 30px;
    padding-right: 120px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.busqueda__head i {
    margin-right: 20px;
}

.cliente {
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.cliente.activo,
.cliente.activo:hover {
    background: #eee;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
    cursor: auto;
    cursor: initial;
}

.cliente.activo ul:hover {
    cursor: pointer;
}

.cliente .clienteDiv,
.vehiculo {
    display: -webkit-flex;
    display: flex;
}

.cliente .clienteDiv li:nth-child(2) {
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    max-width: 230px;
}

.cliente:hover,
.vehiculo:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.cliente span,
.vehiculo span {
    color: rgba(0, 0, 0, 0.6);
}

.cliente .clienteDiv p {
    margin: 5px 0;
}

.cliente .clienteDiv p i {
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.3);
}

.cliente .clienteDiv > ul,
.vehiculo > ul {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 0 0 20px;
}

.cliente .clienteDiv > ul > li:first-child > i,
.vehiculo > ul > li > i {
    padding: 10px;
    margin-right: 5px;
    border-radius: 100%;
}

.cliente .clienteDiv > ul:hover li:first-child i {
    background: rgba(0, 0, 0, 0.05);
}

.vehiculo > ul:hover li:first-child i {
    margin-right: 10px;
    background: rgba(0, 0, 0, 0.05);
}

.cliente.activo .subList {
    max-height: 900px;
}

.cliente .subList {
    max-height: 0;
    overflow: hidden;
    width: 100%;
}

.cliente .subList .empty {
    padding: 0;
}

.cliente .subList .vehiculos {
    margin: 0 15px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.cliente .subList .vehiculos .vehiculo {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.cliente .subList .vehiculos .vehiculo:first-child {
    border: none;
}

.vehiculo > ul li:nth-child(2) {
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    max-width: 125px;
}

.vehiculo > ul li:nth-child(3) {
    -webkit-flex: 2 1 100%;
            flex: 2 1 100%;
}

.options {
    grid-area: options;
    display: inline-block;
    position: relative;
    text-align: right;
    padding: 10px;
}

.options button {
    border-radius: 100%;
}

.options button:focus + ul,
.options ul:hover {
    opacity: 1;
    visibility: visible;
}

.options ul {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    min-width: 150px;
    padding: 10px 0;
    margin: 10px 10px;
    background: #fff;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.options ul li {
    display: list-item;
    width: auto;
    padding: 20px;
}

.options ul li:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
}

.options ul li i {
    margin-right: 15px;
}

#vehiculoInfo {
    grid-area: vehiculoInfo;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    -webkit-align-items: center;
            align-items: center;
    min-width: 600px;
    padding: 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#vehiculoInfo .back {
    border-radius: 100%;
    color: rgba(0, 0, 0, 0.54);
}

#vehiculoInfo > li > div {
    display: inline-block;
    position: relative;
}

#vehiculoInfo > li > div button:focus + ul,
#vehiculoInfo > li > div ul:hover {
    opacity: 1;
    visibility: visible;
}

#vehiculoInfo > li > div ul {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    min-width: 150px;
    background: #fff;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

#vehiculoInfo > li > div ul li {
    display: list-item;
    width: auto;
    padding: 15px 10px;
}

#vehiculoInfo > li {
    text-align: center;
}

#vehiculoInfo p {
    margin: 5px 0;
}

#vehiculoInfo b {
    font-size: 18px;
}

#vehiculoInfo .tag {
    margin-right: 0;
}

#vehiculoInfo span {
    color: rgba(0, 0, 0, 0.6);
}

.info {
    position: relative;
}

.info button {
    text-transform: none;
    color: rgba(0, 0, 0, 0.8);
}

.info button:focus + ul,
.info ul:hover {
    opacity: 1;
    visibility: visible;
    top: 0;
}

.info h5 {
    margin: 0;
}

.info ul {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -15%;
    right: 0;
    z-index: 1000;
    min-width: 300px;
    padding: 10px 0;
    margin: 10px 10px;
    background: #fff;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.info ul li {
    display: grid;
    grid-template-columns: auto 1fr;
    -webkit-align-items: center;
            align-items: center;
    width: auto;
    padding: 20px;
}

.info ul li i {
    margin-right: 15px;
}

#reparaciones {
    grid-area: reparaciones;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}

#reparaciones .reparacion {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
        "fecha fecha remove"
        "titulo titulo titulo"
        "repuestos repuestos repuestos"
        "costo labor total";
    justify-items: stretch;
    -webkit-align-items: center;
            align-items: center;
    background: #fff;
    margin: 20px 50px;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    min-width: 400px;
    max-width: 550px;
}

#reparaciones .reparacion span {
    margin-right: 15px;
}

#reparaciones .reparacion .fecha {
    grid-area: fecha;
    padding: 10px 30px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
}

#reparaciones .reparacion .fecha i {
    font-style: normal;
    font-weight: 500;
    color: #f9a825;
    margin: 0 10px;
    vertical-align: top;
}

#reparaciones .reparacion .remove {
    grid-area: remove;
    text-align: right;
}

#reparaciones .reparacion .remove button {
    padding: 10px;
    margin: 10px;
    border-radius: 100%;
}

#reparaciones .reparacion .titulo {
    grid-area: titulo;
    padding: 10px 30px;
    margin: 0;
}

#reparaciones .reparacion .repuestos {
    grid-area: repuestos;
    padding: 20px 30px;
    margin: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
}

#reparaciones .reparacion .costo {
    grid-area: costo;
    padding: 20px 30px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.54);
}

#reparaciones .reparacion .labor {
    grid-area: labor;
    padding: 20px 0px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.54);
}

#reparaciones .reparacion .total {
    grid-area: total;
    padding: 20px 30px;
    text-align: right;
    font-size: 18px;
}

#reparaciones .reparacion b {
    color: #4caf50;
}

.add {
    padding: 20px;
    text-align: center;
}

.add .material-icons {
    margin-right: 10px;
    color: #2196f3;
}

.add button {
    border: 1px solid #2196f3;
}

#reparaciones .add {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    padding-top: 10px;
}

#reparaciones .add .material-icons {
    color: rgba(255, 255, 255, 1);
}

#reparaciones .add button {
    color: rgba(255, 255, 255, 1);
    background: #2196f3;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

#reparaciones .add button:hover {
    background-color: #1976d2;
    border: 1px solid #1976d2;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 1340px) {
    #SearchView {
        padding: 35px;
        background: none;
    }

    .searchCard {
        height: auto;
    }

    .searchCard--active {
        max-width: 1260px;
        border-radius: 8px 8px 8px 8px;
    }
}

#TurnosView {
    text-align: center;
    max-height: 100vh;
    padding: 42px;
}

#TurnosView .fab {
    display: inline-block;
    vertical-align: top;
    margin-top: 25px;
    margin-left: 50px;
}

#turnos {
    display: inline-block;
    overflow-y: auto;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    min-width: 500px;
    max-width: 700px;
    max-height: 100%;
    text-align: left;
}

.turno:first-child .turno__mes {
    border-top: none;
}

.turno__mes {
    padding: 20px;
    background: #f5f5f5;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.turno--date ul {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.turno ul {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.turno__fecha {
    width: 120px;
    padding: 0 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.turno__fecha .day {
    text-align: left;
    text-transform: uppercase;
    font-family: "Roboto Mono", monospace;
    color: rgba(0, 0, 0, 0.54);
}

.turno__fecha .date {
    text-align: center;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.8);
}

.turno__cuerpo {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 0 20px;
    font-weight: 500;
}

.turno span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
}

.turno button {
    border-radius: 100%;
}

#PresupuestosView {
    padding: 35px;
    display: grid;
    justify-items: center;
    -webkit-align-items: start;
            align-items: start;
}

#presupuestos {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "listaHead mensajeHead"
        "lista mensaje";
    justify-items: stretch;
    -webkit-align-items: stretch;
            align-items: stretch;
    max-width: 450px;
    transition: 0.8s ease-in-out;
}

#presupuestos #listaHead {
    grid-area: listaHead;
    position: relative;
    padding: 20px;
    background: #f5f5f5;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#presupuestos #listaHead h6 {
    margin: 0;
    color: rgba(0, 0, 0, 0.54);
}

#listaHead .fab {
    position: absolute;
    right: 0;
    bottom: -50%;
    z-index: 500;
    padding: 0 25px;
}

#presupuestos #lista {
    grid-area: lista;
    min-width: 450px;
    max-width: 450px;
    padding-top: 20px;
    overflow-y: auto;
}

#presupuestos #mensajeHead {
    grid-area: mensajeHead;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    min-width: 600px;
    padding: 0 10px;
    background: #f5f5f5;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#presupuestos #mensajeHead > button {
    border-radius: 100%;
}

#presupuestos #mensajeHead select {
    width: auto;
    height: auto;
    border: none;
    background: none;
    padding: 0 0 0 0;
}

#presupuestos #mensajeHead .enviar {
    display: inline-block;
    justify-self: end;
    max-width: 435px;
    padding: 0;
    margin: 10px;
    border-radius: 5px;
    background: #fff;
    text-align: right;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

#presupuestos #mensajeHead .enviar input[type="email"] {
    width: 250px;
    margin: 0;
    background: none;
    border: none;
}

#presupuestos #mensajeHead .enviar button {
    color: rgba(255, 255, 255, 1);
    background: #2196f3;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

#presupuestos #mensajeHead .enviar button:hover {
    background-color: #1976d2;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

#presupuestos #mensaje {
    grid-area: mensaje;
    overflow-y: scroll;
    max-width: 670px;
    max-height: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

#presupuestos .presupuesto {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    cursor: pointer;
}

#presupuestos .presupuesto:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

#presupuestos .presupuesto li {
    display: inline-block;
    padding: 10px;
}

#presupuestos .presupuesto li:first-child {
    color: rgba(0, 0, 0, 0.54);
}

#presupuestos .presupuesto li span {
    margin: 0 10px;
    color: rgba(0, 0, 0, 0.54);
}

#presupuestos .presupuesto button {
    border-radius: 100%;
}

#patente {
    grid-area: patente;
}

#patente input {
    text-transform: uppercase;
}

#km {
    grid-area: km;
}

#reparacion {
    grid-area: reparacion;
}

#reparacion input {
    text-transform: capitalize;
}

#labor {
    grid-area: labor;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
}

.overlay--show {
    opacity: 1;
    visibility: visible;
}

.dialogs {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    visibility: hidden;
    z-index: 5;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    pointer-events: none;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.dialogs--show {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.dialog {
    background: #fff;
    min-width: 300px;
    max-width: 500px;
    max-height: 100%;
    padding: 10px 0;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.5);
    pointer-events: all;
    text-align: left;
}

.dialog__head {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 40px 35px;
}

.dialog__head h5 {
    margin: 0;
}

.info {
    display: inline-block;
    text-align: right;
}

.info i {
    margin: 0 10px;
}

.dialog .body {
    grid-area: body;
    margin: 25px 30px;
    color: rgba(0, 0, 0, 0.6);
}

.dialog__buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    padding: 10px 20px 10px 20px;
}

.dialog__total {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 0px 20px;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.dialog__total b {
    font-size: 18px;
    color: #4caf50;
    margin-left: 10px;
}

.dialog > ul {
    display: grid;
    justify-items: stretch;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 10px 30px;
    gap: 10px 30px;
    padding: 0px 30px;
}

.dialog span {
    margin: 0 10px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
}

.dialog .error span {
    color: #ff0000;
}

.dialog .error input {
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid red;
}

#addCliente ul {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "nombre apellido"
        "telefono1 telefono2"
        "direccion empresa";
    justify-items: stretch;
    -webkit-align-items: end;
            align-items: end;
}

#addCliente #nombre {
    grid-area: nombre;
}

#addCliente #nombre input {
    text-transform: capitalize;
}

#addCliente #apellido {
    grid-area: apellido;
}

#addCliente #apellido input {
    text-transform: capitalize;
}

#addCliente #telefono1 {
    grid-area: telefono1;
}

#addCliente #telefono2 {
    grid-area: telefono2;
}

#addCliente #direccion {
    grid-area: direccion;
}

#addCliente #direccion input {
    text-transform: capitalize;
}

#addCliente .empresa {
    grid-area: empresa;
}

#addVehiculo ul {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "patente year combustible"
        "tipo tipo tipo";
}

#addVehiculo #patente {
    grid-area: patente;
}

#addVehiculo #patente input {
    text-transform: uppercase;
}

#addVehiculo #patente p {
    position: absolute;
    font-size: 12px;
    margin: 0;
    color: rgba(0, 0, 0, 0);
}

#addVehiculo #patente.error p {
    color: rgba(255, 0, 0, 1);
}

#addVehiculo #year {
    grid-area: year;
}

#addVehiculo #combustible {
    grid-area: combustible;
}

#addVehiculo #vin {
    margin-right: 20px;
}

#addVehiculo #vin input {
    text-transform: uppercase;
}

#editVehiculo input[type="search"] {
    text-transform: capitalize;
    border-radius: 4px 4px 0 0;
    margin-bottom: 0;
}

#editVehiculo input[type="search"]:focus {
    border-bottom: 2px solid #2196f3;
}

#editVehiculo #datalist {
    background: #fff;
    max-height: 300px;
    padding: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
}

#editVehiculo #datalist .empty {
    padding: 20px 0;
}

#editVehiculo #datalist li {
    width: 100%;
}

#editVehiculo #datalist li input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

#editVehiculo #datalist li label {
    display: block;
    width: 100%;
    padding: 15px;
    cursor: pointer;
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

#editVehiculo #datalist li label:hover {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

#editVehiculo #datalist li input:checked ~ label {
    background: #2196f3;
    color: #fff;
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.3);
}

#addReparacion ul {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
        "fecha km ultimo"
        "reparacion reparacion labor"
        "repuestos repuestos costo";
}

#addReparacion #fecha {
    grid-area: fecha;
}

#addReparacion #ultimo {
    grid-area: ultimo;
    text-align: right;
}

#addReparacion #ultimo p {
    color: #f9a825;
    font-size: 18px;
    font-weight: 500;
}

#addReparacion #km {
    grid-area: km;
}

#addReparacion #reparacion {
    grid-area: reparacion;
}

#addReparacion #reparacion input {
    text-transform: capitalize;
}

#addReparacion #repuestos {
    grid-area: repuestos;
}

#addReparacion #repuestos input {
    text-transform: capitalize;
}

#addReparacion #costo {
    grid-area: costo;
}

#addReparacion #labor {
    grid-area: labor;
}

#addTurno ul {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "title fecha"
        "motivo motivo"
        "tipo tipo";
}

#addTurno #fecha {
    grid-area: fecha;
}

#addTurno #motivo {
    grid-area: motivo;
}

#addTurno #motivo input {
    text-transform: capitalize;
}

#addPresupuesto.dialog {
    min-width: 700px;
    max-width: 800px;
    overflow-y: auto;
}

#addPresupuesto ul {
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "title title title"
        "patente tipo km"
        "reparacion reparacion labor"
        "repuestos repuestos repuestos"
        "total buttons buttons";
    min-width: 700px;
    max-width: 800px;
}

#addPresupuesto #repuestos {
    grid-area: repuestos;
    margin: 20px 0;
    max-height: 100%;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

#addPresupuesto #repuestos .empty {
    padding: 20px 30px;
}

#addPresupuesto #repuestos ul {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "cantidad repuesto precio add";
    justify-items: stretch;
    -webkit-align-items: center;
            align-items: center;
}

#addPresupuesto #repuestos #columnHeader {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.54);
}

#addPresupuesto #repuestos #columnHeader li {
    text-align: left;
}

#addPresupuesto #repuestos li {
    display: inline-block;
    padding: 20px 30px;
    text-align: right;
}

#addPresupuesto #repuestos li:nth-child(2) {
    text-align: left;
}

#addPresupuesto #repuestos input {
    margin: 0;
    border-radius: 0;
    border-top: none;
    border-left: none;
    text-transform: capitalize;
}

#addPresupuesto #repuestos #addRepuesto {
    padding: 0;
    background: #f5f5f5;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#addPresupuesto #repuestos #addRepuesto li {
    padding: 0;
}

#addPresupuesto #repuestos #addRepuesto button i {
    color: #2196f3;
}

