.header {
    position: fixed;
    z-index: 2;
    opacity: 1;
    height: 100%;
    max-width: 80px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.54);
}

.header:hover {
    max-width: 250px;
}

.header > div:first-child {
    display: flex;
    flex-direction: column;
    padding-bottom: 70px;
    height: 100%;
    min-width: 250px;
}

.header__logo {
    display: block;
    height: 102px;
    width: 100%;
    padding-top: 10px;
}

.header__logo img {
    display: inline-block;
    width: 80px;
    padding: 15px;
    vertical-align: middle;
}

.header .links {
    flex-grow: 1;
}

.header__link {
    display: block;
    padding: 20px 0px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    border-left: 0px solid #455a64;
}

.header__link .md-dark {
    color: #263238;
    margin: 0 27px;
}

.header__link--active .md-dark {
    color: #fdd835;
}

.header__link:hover {
    border-left: 5px solid #455a64;
}

.header__link span {
    font-weight: 400;
    opacity: 0;
    padding: 7px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin-left: 0px;
}

.header:hover .header__link span {
    opacity: 1;
    margin-left: 10px;
}

.header__logout {
    width: 100%;
    padding: 10px 15px;
    margin: 0;
    border-left: 5px solid rgba(0, 0, 0, 0);
    text-align: left;
    font-size: 14px;
}

.header__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 14px;
    max-width: 80px;
    text-align: center;
    padding-bottom: 35px;
    color: rgba(0, 0, 0, 0.3);
}

.headBar {
    position: fixed;
    right: 0;
    z-index: 0;
    width: calc(100vw - 80px);
    background: #b0bec5;
    height: 102px;
    border-radius: 0 0 0 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}
