#TurnosView {
    text-align: center;
    max-height: 100vh;
    padding: 42px;
}

#TurnosView .fab {
    display: inline-block;
    vertical-align: top;
    margin-top: 25px;
    margin-left: 50px;
}

#turnos {
    display: inline-block;
    overflow-y: auto;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    min-width: 500px;
    max-width: 700px;
    max-height: 100%;
    text-align: left;
}

.turno:first-child .turno__mes {
    border-top: none;
}

.turno__mes {
    padding: 20px;
    background: #f5f5f5;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.turno--date ul {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.turno ul {
    display: flex;
    align-items: center;
}

.turno__fecha {
    width: 120px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.turno__fecha .day {
    text-align: left;
    text-transform: uppercase;
    font-family: "Roboto Mono", monospace;
    color: rgba(0, 0, 0, 0.54);
}

.turno__fecha .date {
    text-align: center;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.8);
}

.turno__cuerpo {
    flex-grow: 1;
    padding: 0 20px;
    font-weight: 500;
}

.turno span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
}

.turno button {
    border-radius: 100%;
}
